import React from "react"
import Seo from "../../components/SEO"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import GalleryPage from "../../components/Portfolios/GalleryPage"
import paths from "../../constants/paths"

const Container = styled.div``

const CouplesPortfolio = ({ location }) => {
  const imagesQueryResult = useStaticQuery(query)
  return (
    <React.StrictMode>
      <Container>
        <Seo title={paths.portfolioCouples.title} location={location} />
        <GalleryPage imagesQueryResult={imagesQueryResult} />
      </Container>
    </React.StrictMode>
  )
}

export default CouplesPortfolio

// Queries are exactly the same except for the page limit
// TODO: Is there a better way to do this?
// TODO: How can we run this and randomize the results in the gatsby-node.js?
let query = graphql`
  fragment fluidCouplesPortfolioImage on File {
    name
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 1400)
    }
    childrenImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 2880)
    }
  }

  query CouplesPortfolioImages {
    allFile(
      filter: { relativePath: { glob: "portfolios/couples/*" } }
      sort: { fields: [id], order: ASC }
    ) {
      edges {
        node {
          id
          name
          ...fluidCouplesPortfolioImage
        }
      }
    }
  }
`
